import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useGetAllProjectsQuery } from '../../state/services';
import { Project } from 'state/types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { currencyFormatter } from '../../utils';

const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          console.log({ tooltipItem  })
          const value = tooltipItem.raw;
          return `${tooltipItem.dataset.label}: ${currencyFormatter().format(value)}`;
        },
      },
    },
    datalabels: {
      formatter: (value, context) => {
        const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
        const percentage = ((value / total) * 100) <= 0 ? '' : ((value / total) * 100).toFixed(2) + '%';
        return percentage;
      },
      color: '#000',
    },
  },
};

const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const a = 0.2; // Adjust the alpha value as needed
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const getAwardedPerAccount = (projects: Project[]) => {
  const awardedPerAccount = projects.reduce((acc: Record<number, number>, project: Project) => {
    if (!acc[project.account_id]) {
      acc[project.account_id] = 0
    }

    acc[project.account_id] += parseFloat(project.awarded)
    return acc
  }, {})

  return awardedPerAccount
}

type Data = {
  labels: string[];
  datasets: DataSet[];
}

type DataSet = {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const PieChart = () => {
  // Temporarily using getallProjects similar to projects page. Business logic and calculations should be moved to the backend
  const queryData = useGetAllProjectsQuery({}, { refetchOnMountOrArgChange: false, refetchOnReconnect: true })
  const [projectsData, setProjectsData] = useState<Data>({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    if (queryData.isError) {
      console.error(`Error with PieChart getting data: ${JSON.stringify(queryData.error)}`)
      return
    }
    if (!queryData.isSuccess) {
      return
    }


    // Finds unique account_name for each project
    const labels = queryData.data.projects.reduce((acc: string[], project: Project) => {
      if (!acc.includes(project.account_name)) {
        acc.push(project.account_name)
      }
      return acc
    }, [])

    const dataset = queryData.data.projects.reduce((acc: DataSet, project: Project, index) => {
      acc.backgroundColor.push(getRandomColor())
      const matchingAccountNameIndex = labels.indexOf(project.account_name)
      acc.data[matchingAccountNameIndex] = acc.data[matchingAccountNameIndex] ? acc.data[matchingAccountNameIndex] + parseFloat(project.awarded) : parseFloat(project.awarded)
      return acc
    }, {
      label: 'Total Awarded',
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    })

    setProjectsData({
      labels,
      datasets: [dataset],
    })
  }, [queryData.isError, queryData.isSuccess, queryData.data])

  return (
    <div className="flex flex-col items-center justify-center h-[95%]">
     
      {
        queryData.isLoading && <div>Loading...</div>
      }
      {
        queryData.isError && <div>Error: {JSON.stringify(queryData.error)}</div>
      }
      {
        queryData.isSuccess && <Pie
          data={projectsData}
          width={100}
          height={100}
          options={options}
        />
      }
      <h3 className="text-bold">
        Total awarded per account
      </h3>
    </div>
  )
}