import { useDispatch } from "react-redux";
import { Firm, User } from 'state/types';
import { AuthState, setLogin } from '../../state/services';
import { Login } from '../../state/types';
import { createOptions, getApiUrl } from '../../utils';

type CreateFirm = {
  firm: Firm
  user: User & Login
}

export const useCreateFirm = () => {
  const url = getApiUrl('api/firms')
  const dispatch = useDispatch()

  const autoLogin = (response: AuthState) => {
    dispatch(setLogin(response))
    return
  }

  const createFirm = async ({
    firm,
    user
  }: CreateFirm) => {
    try {
      // TODO: Use RTK query, and also try not to assign each property for firm and user object because if you add or delete new items, it won't get reflected in the API call here
      const data = await fetch(url, createOptions('POST', {
        body: {
          firm: {
            name: firm.name,
            unit: firm.unit,
            street_address: firm.street_address,
            city: firm.city,
            province: firm.province,
            country: firm.country,
            postal_code: firm.postal_code,
            phone: firm.phone,
            email: firm.email,
            gst_number: firm.gst_number,
            hst_number: firm.hst_number,
            pst_number: firm.pst_number,
            fiscal_start: firm.fiscal_start,
          },
          user: {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            password: user.password,
          }
        }
      }))

      if (!data.ok) {
        console.error(`useCreateFirm::Login failed with status ${data.status}, check credentials again`)
      }

      const jsoned = await data.json()

      autoLogin({
        firm: jsoned.firm as Firm,
        user: jsoned.user as User,
        isLoggedIn: true,
        userType: (jsoned.user as User).role
      })
      return jsoned
    } catch (error) {
      console.error('useCreateFirm::Error creating firm', error)
      throw new Error('create firm failed')
    }
  }

  return {
    createFirm,
  }
}