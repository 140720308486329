import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AddProjectModal } from "../../components/add-project-modal";
import { TAILWIND_CLASSES, buildAddress, currencyFormatter } from '../../utils';

import { useDispatch, useSelector } from "react-redux";
import { Firm, Project, UserResponse, } from "state/types";
import { SearchInput } from "../../components/search-input";
import { selectProjectStatus, setInstanceProjectStatus } from "../../state/instances";
import { ALLOWED_ROLES, selectIsLoggedIn, selectUserType, useDeleteProjectMutation, useGetAllProjectsQuery, useUpdateProjectMutation, useUpdateProjectNumberMutation } from '../../state/services';
import { useListenForKeyboardPress } from "../../utils/hooks";
import { CommentsModal } from "./comments-modal";
import { Address } from "./edit-address-modal";
import { EditProjectRow } from "./edit-project-row";
import { InvoiceAndPaymentsModal } from './invoice/invoice-and-payments-modal';
import './projects.css';
import { StatusBar } from "./status-bar";
import { ListOfProjectTypes, useToggleListOfProjectTypes } from "./list-of-project-types";
import { PROJECT_COLUMNS } from "./types";

// Extract this for runtime typing
const numberizefee = (projects: Project[]) => {
  return projects.map((project) => {
    return {
      ...project,
      fee: parseFloat(project.fee as unknown as string),
      // invoiced_fee: project.invoiced_fee ? typeof project.invoiced_fee === 'string'
      //   ? Number(parseFloat(project.invoiced_fee).toFixed())
      //   : Number(project.invoiced_fee.toFixed()) : 0
    }
  })
}

const sortProjectsByStatus = (projects: Project[], testing?: boolean): {
  sortedProjects: Record<Project['status'], Project[]>;
  numberOfAllProjects: number;
} => {
  // TODO: Table has no restrictions on what status can be filled, just a string type...
  // Initialize an object to store projects by status
  // Also consolidate the typing of project
  const sortedProjects: Record<string, Project[]> = {
    'ALL': [],
    'ACTIVE': [],
    // TODO: Update these keys to these
    'PENDING PROPOSAL': [],
    'PROPOSAL SENT': [],
    'COMPLETE': [],
    'LOST': [],
  };


  let numberOfAllProjects = 0

  // Populate projects by status
  projects.forEach(project => {
    sortedProjects[project.status.toUpperCase()].push(project);
    sortedProjects['ALL'].push(project)
    numberOfAllProjects += 1
  });

  return {
    sortedProjects,
    numberOfAllProjects,
  }
};

const calcTotalfeeForCurrentQuarter = (projects: Project[]): string => {
  const totalFees = projects.reduce((accum, current) => {
    return accum += Number(current.fee)
  }, 0)
  // TODO: use utils for currencyformatter
  return totalFees.toLocaleString('en-US', {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'symbol'
  }).substring(3)
}

// TODO: color update




const initError = {
  isError: false,
  message: '',
}



const columnsProjectMap = {
  'Project #': 'project_number',
  Account: 'account_name',
  'Site': 'site',
  'Site Address': 'street_address',
  'Project Type': 'project_type',
  Status: 'status',
  'Total Fees': 'fee',
  Awarded: 'awarded',
  Comments: 'comments'
}

function sortProjects(projects, sortBy, sortDirection) {
  if (sortDirection === '') {
    return projects
  }

  return projects.sort((a, b) => {
    // Extract values for comparison
    const valueA = a[sortBy];
    const valueB = b[sortBy];

    // Determine sorting order based on sortDirection parameter
    const multiplier = sortDirection === 'desc' ? -1 : 1;

    // Compare values based on their types
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      // Case-insensitive string comparison
      return multiplier * valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      return multiplier * (valueA - valueB); // Numeric comparison
    } else {
      // Fallback: compare as strings
      return multiplier * String(valueA).localeCompare(String(valueB));
    }
  });
}

// TODO: Extract
const searchProjects = (projects: Project[], searchQuery: string, category?: keyof Project) => {
  return projects.filter(project => {
    if (category) {
      const value = project[category]
      // TODO: This turns numbers into string and compares...need a better way to compare currency
      if (value.toString().toLowerCase() === searchQuery.toString().toLowerCase()) {
        return true
      }
      return false
    }

    const values = Object.values({
      ...project,
      // This is so that search query can look for built addresses
      builtAddress: buildAddress({
        unit: project.unit,
        street_address: project.street_address,
        city: project.city,
        province: project.province,
        country: project.country,
        postal_code: project.postal_code
      }).builtAddress
    });

    return values.some(value =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
}

export const Projects = () => {
  const dispatch = useDispatch()
  useListenForKeyboardPress('Escape', () => {
    setSearchCategory(null)
    setSearchQuery('')
  })
  const navigate = useNavigate()
  const location = useLocation()

  const [searchQuery, setSearchQuery] = useState('')
  const [searchCategory, setSearchCategory] = useState<keyof Project | null>()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const userType = useSelector(selectUserType);
  const [isSavingViaEditProjectRowType, setIsSavingViaEditProjectRowType] = useState(false)

  // TODO: Consolidate sort functionality
  const [sortLastClicked, setSortLastClicked] = useState<typeof PROJECT_COLUMNS[number]>('')
  const [sortType, setSortType] = useState('Site')
  const [sortDirection, setSortDirection] = useState({
    'Project #': 'asc',
    'Account': 'asc',
    'Site': 'asc',
    'Site Address': 'asc',
    'Project Type': 'asc',
    'Status': 'asc',
    'Total Fees': 'asc',
    'Comments': 'asc'
  })
  // TODO: Global state to store projects, firm, user, clients
  const [unique_project_types, setUnique_project_types] = useState([])
  const [firm, setFirm] = useState<Firm | null>()
  const [user, setUser] = useState<UserResponse | null>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allProjectsData, setAllProjectsData] = useState<Project[]>()
  const selectedProjectStatus = useSelector(selectProjectStatus)
  const [filterValues, setFilterValues] = useState<{
    fiscal_year?: number[],
    quarter?: number[],
  }>({})


  const [projectToEdit, setProjectToEdit] = useState<Project>({
    id: 0,
    site: '',
    unit: '',
    street_address: '',
    city: '',
    province: '',
    postal_code: '',
    country: '',
    fee: 0,
    project_number: 0,
    project_type: '',
    status: 'ALL',
    account_id: 0,
    account_name: '',
    comments: '',
  })
  const [editStatus, setEditStatus] = useState<number | undefined>()
  const [isCommentsOpen, setIsCommentsOpen] = useState<number | undefined>()
  const [isInvoiceAndPaymentsOpen, setIsInvoiceAndPaymentsOpen] = useState<number | undefined>()

  // TODO: dont need local state for error now with rtk query?
  const [error, setError] = useState(initError)
  // const [isAddingDynamicColumns, setIsAddingDynamicColumns] = useState(false)
  const queryData = useGetAllProjectsQuery('placeholder', { refetchOnMountOrArgChange: true, refetchOnReconnect: true })
  // const customColumnsData = useGetColumnsQuery('')
  const [updateProject] = useUpdateProjectMutation()
  const [deleteProject] = useDeleteProjectMutation()
  const [updateProjectNumber, updateProjectNumberResult] = useUpdateProjectNumberMutation()

  const { isListOfProjectTypesOpen, addedProjectTypes, updateAddedProjectTypes, toggleListOfProjectTypes } = useToggleListOfProjectTypes();

  const filterByProjectTypes = (projectType: string) => {
    updateAddedProjectTypes(projectType);
  }
  const projectsFilteredByProjectTypes = addedProjectTypes.length === 0
    ? allProjectsData
    : allProjectsData?.filter((project) => addedProjectTypes.includes(project.project_type.toLowerCase()))

  // TODO: Improve updating just project number...rerenders too much!
  useEffect(() => {
    if (!updateProjectNumberResult.data) {
      return
    }
    if (updateProjectNumberResult.data?.project_number_new !== projectToEdit.project_number) {
      setProjectToEdit({
        ...projectToEdit,
        project_number: updateProjectNumberResult.data?.project_number_new,
      })
    }
  }, [projectToEdit, updateProjectNumberResult.data, updateProjectNumberResult.data?.project_number_new])

  useEffect(() => {
    if (queryData.isError) {
      console.error(`Error with projects.tsx:: ${JSON.stringify(queryData.error)}`)
      setError({
        isError: true,
        message: 'There was a problem with the fetch request, please contact customer support'
      })
      return
    }
    if (!queryData.isSuccess) {
      return
    }
    const formatted = numberizefee(queryData.data.projects)
    setAllProjectsData(formatted)
    setUnique_project_types(queryData.data.unique_project_types)
    setUser(queryData.data.user)
    setFirm(queryData.data.firm)
    setError(initError)
    // Bandaid fix to update project to edit for InvoiceAndPaymentsModal to get latest data
    setProjectToEdit(formatted.find((project) => projectToEdit.id === project.id) ?? projectToEdit)


  }, [queryData.data, queryData.isError, queryData.isSuccess, queryData.error, queryData])

  useEffect(() => {
    if (location.pathname !== '/projects') {
      navigate('/projects')
    }
    // getAllProjectsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TEMP FIX TO RESET STATUS IN REDUX
  useEffect(() => {
    dispatch(setInstanceProjectStatus({ status: 'ACTIVE' }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (queryData.isLoading) {
    return (<div>Loading data...</div>)
  }

  if (!isLoggedIn) {
    return (
      <div>
        You are not logged in.
        <div>
          <button
            className="w-full px-4 py-2 mt-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            type="button"
            onClick={() => { navigate('/login') }}
          >Click here to go to the login page</button>
        </div>
      </div>
    )
  }

  // TODO: Better way to handle errors with firm, user, etc
  if (error.isError || !firm || !user || !queryData.data) {
    return <div className="flex flex-col items-center justify-center max-w-screen-xl w-full h-full">
      <div className="flex flex-col gap-4 h-full">
        <h2 className="text-gray-800 text-xl font-bold sm:text-2xl">{error.message}. Please login and try again</h2>
        <a href="/login"><span>Click here to navigate to login</span></a>
      </div>
    </div>
  }

  if (!allProjectsData) {
    return <div className="flex items-center justify-center max-w-screen-xl w-min md:w-full px-4 md:px-4">Loading data</div>
  }

  // TODO: MAJOR TECH DEBT IN DEPENDING ON SORTPROJECTBYSTATUS
  const { sortedProjects } = sortProjectsByStatus(projectsFilteredByProjectTypes)

  const statuses = Object.keys(sortedProjects) as Project['status'][]
  const filteredByStatus = sortedProjects[selectedProjectStatus]
  const filteredByStatusProjects = filteredByStatus.map((filtered) => filtered)

  const searchedProjects = searchProjects(filteredByStatusProjects, searchQuery, searchCategory)
  const tableSorted = sortProjects(searchedProjects, columnsProjectMap[sortType], sortDirection[sortType])


  const isEditingProjectRow = editStatus !== undefined

  // TODO: remove accountId arg
  const handleClickProject = (accountId, projectNumber) => {

    navigate(`/project/${projectNumber}`);
  }

  const handleClickAccount = (accountId) => {
    navigate(`/account?accountId=${accountId}`)
  }

  const openAddClientModal = () => {
    setIsModalOpen(true)
  }

  const warnDisabledClick = () => {
    alert("You can't perform this action while editing a project row")
  }

  return (
    <>
      <div className="flex items-center justify-center w-min md:w-full px-4 md:px-4 sm:w-1/2 h-full">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="max-w-lg">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              {firm.name}
            </h3>
            <p className="text-gray-600 mt-2">
              User: {`${user.first_name} ${user.last_name}`}
            </p>
          </div>
          <div className="text-sm w-full max-w-[1800px]">
            <div className="w-full flex justify-between">
              <div className="w-[310px] flex gap-4">
                <SearchInput
                  disabled={isEditingProjectRow}
                  value={searchQuery}
                  onChange={(e) => {
                    // Clear search category when typing in search bar. This is so that when a search category is set by clicking on a cell, then deleting the query in the search bar, searchCategory is still set
                    if (searchCategory) {
                      setSearchCategory(undefined)
                    }
                    setSearchQuery(e.target.value)
                  }}
                />
                <button
                  disabled={searchQuery === ''}
                  className={`${searchQuery === '' ? `${TAILWIND_CLASSES.disabled} ` : ''}w-auto flex items-center gap-2 px-5 py-3 text-white duration-150 bg-blue-600 rounded-lg hover:bg-sky-700 active:bg-sky-500`}
                  type="button"
                  onClick={() => {
                    setSearchQuery('')
                    setSearchCategory(undefined)
                  }}
                >
                  Clear
                </button>
              </div>
              <button
                className={`${isEditingProjectRow ? `cursor-not-allowed ${TAILWIND_CLASSES.disabled}` : 'bg-blue-600  hover:bg-sky-700 active:bg-sky-500'} w-auto flex items-center gap-2 px-5 py-3 text-white duration-150 rounded-lg`}
                type="button"
                onClick={isEditingProjectRow ? warnDisabledClick : openAddClientModal}
              >
                Add Project
              </button>
            </div>
            <div className="flex">
              <div className="w-full">
                <StatusBar statuses={statuses} isEditingProjectRow={isEditingProjectRow} projects={sortedProjects}
                  handleStatusClick={(status) => {
                    dispatch(setInstanceProjectStatus({ status }))
                  }}
                />
                <div>
                  <div id="projects-table" className={`${tableSorted.length > 10 ? ' h-[60vh] overflow-scroll' : ''}`}>
                    <table className="w-full table-auto text-left">
                      <colgroup>
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '8%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '5%' }} />
                      </colgroup>
                      <thead className="text-gray-600 font-medium border-b">
                        <tr>
                          <>
                            {
                              PROJECT_COLUMNS.map((column) => {
                                return (
                                  <th
                                    key={column}
                                    className={`${isEditingProjectRow ? 'cursor-not-allowed' : 'cursor-pointer'} py-4 px-2${sortLastClicked === column ? ' text-blue-600' : ''}`}
                                  >
                                    <div className="flex justify-left items-center gap-2 relative">
                                      <button type="button" onClick={column === 'Project Type' ? () => {
                                        toggleListOfProjectTypes()
                                      } : null}>
                                        <span>
                                          {column}
                                        </span>
                                      </button>
                                      <div><button type="button" onClick={isEditingProjectRow ? warnDisabledClick : () => {
                                        // If selecting new column, always ascending. Otherwise, toggle between asc/desc
                                        const sortDirectionValue = column !== sortType ? {
                                          ...sortDirection,
                                          [column]: 'asc'
                                        } : sortDirection[column] === 'asc' ? { ...sortDirection, [column]: 'desc' } : { ...sortDirection, [column]: 'asc' }
                                        setSortType(column)
                                        setSortDirection(sortDirectionValue)
                                        setSortLastClicked(column)
                                      }}>{sortDirection[column] === 'asc' ? String.fromCharCode(9660) : String.fromCharCode(9650)}</button></div>
                                      {
                                        isListOfProjectTypesOpen && column === 'Project Type'
                                          ? <ListOfProjectTypes addedProjectTypes={addedProjectTypes} projects={allProjectsData} handleClick={filterByProjectTypes} closeList={toggleListOfProjectTypes} />
                                          : null
                                      }
                                    </div>
                                  </th>
                                )
                              })
                            }
                          </>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 divide-y">
                        {
                          tableSorted.map((project, idx) => {
                            const { builtAddress } = buildAddress({ unit: project.unit, street_address: project.street_address, city: project.city, province: project.province, country: project.country, postal_code: project.postal_code, })
                            const isProjectRowNotEditing = editStatus !== project.id && editStatus !== undefined
                            return (
                              <>
                                {
                                  isInvoiceAndPaymentsOpen === project.id
                                    ? <InvoiceAndPaymentsModal
                                      projects={tableSorted}
                                      key={project.id}
                                      projectId={project.id}
                                      closeModal={() => { setIsInvoiceAndPaymentsOpen(undefined) }}
                                    />
                                    : null
                                }
                                {
                                  idx === 0 && ALLOWED_ROLES.includes(userType) ? (
                                    <tr className="pt-4 pb-[300px] group-hover:text-blue-600 text-green-600">
                                      <td className="py-4 px-2" style={{ width: '5%' }} >Totals</td>
                                      <td className="py-4 px-2" style={{ width: '10%' }} ></td>
                                      <td className="py-4 px-2" style={{ width: '15%' }} ></td>
                                      <td className="py-4 px-2" style={{ width: '15%' }} ></td>
                                      <td className="py-4 px-2" style={{ width: '10%' }} >{tableSorted.length} projects</td>
                                      <td className="py-4 px-2" style={{ width: '10%' }} ></td>
                                      <td className="py-4 px-2" style={{ width: '10%' }} >
                                        <div>${calcTotalfeeForCurrentQuarter(tableSorted)}</div>
                                      </td>
                                      <td className="py-4 px-2" style={{ width: '8%' }} >
                                        <div>
                                          YTD: {currencyFormatter().format(queryData?.data?.total_year_awarded)}
                                        </div>
                                        <div>
                                          QTD: {currencyFormatter().format(queryData?.data?.total_quarter_awarded)}
                                        </div>
                                      </td>
                                      <td className="py-4 px-2" style={{ width: '5%' }} ></td>
                                      <td className="py-4 px-2" style={{ width: '5%' }} ></td>
                                      <td className="py-4 px-2" style={{ width: '5%' }} ></td>
                                    </tr>
                                  ) : null
                                }
                                {
                                  editStatus === project.id ? <EditProjectRow
                                    key={project.id}
                                    isSavingViaEditProjectRowType={isSavingViaEditProjectRowType}
                                    allProjectsData={allProjectsData}
                                    idx={idx}
                                    projectToEdit={projectToEdit}
                                    saveEdit={async (key: string, value: string, saveToBackend?: boolean) => {
                                      const projectUpdated = {
                                        ...projectToEdit,
                                        [key]: value
                                      }
                                      setProjectToEdit(projectUpdated)
                                      if (saveToBackend) {
                                        updateProject(projectUpdated)
                                      }
                                    }}
                                    saveAddress={async (addressInfo: Address) => {
                                      setIsSavingViaEditProjectRowType(true)
                                      // TODO: single action, rather than updating state plus api call
                                      setProjectToEdit({
                                        ...projectToEdit,
                                        unit: addressInfo.unit,
                                        street_address: addressInfo.street_address,
                                        city: addressInfo.city,
                                        province: addressInfo.province,
                                        postal_code: addressInfo.postal_code,
                                        country: addressInfo.country,
                                      })
                                      await updateProject({
                                        ...projectToEdit,
                                        unit: addressInfo.unit,
                                        street_address: addressInfo.street_address,
                                        city: addressInfo.city,
                                        province: addressInfo.province,
                                        postal_code: addressInfo.postal_code,
                                        country: addressInfo.country,
                                      })
                                      setEditStatus(undefined)
                                      setIsSavingViaEditProjectRowType(false)
                                    }}
                                    handleSaveClick={async (isSaving) => {
                                      setIsSavingViaEditProjectRowType(true)
                                      if (isSaving) {
                                        try {
                                          await updateProject(projectToEdit)
                                        } catch (error) {
                                          console.error('Error saving project', error)
                                          alert('There was an error saving, please contact the administrator')
                                        } finally {
                                          setEditStatus(undefined)
                                          setIsSavingViaEditProjectRowType(false)
                                        }
                                      }
                                      setEditStatus(undefined)
                                    }}
                                    updateProjectNumber={(projectNumberPayload) => {
                                      updateProjectNumber(projectNumberPayload)
                                    }}
                                  /> : <>
                                    <tr
                                      key={idx}
                                      className={`h-[70px] group ${idx % 2 > 0 ? 'bg-slate-200' : 'bg-slate-50'} ${isProjectRowNotEditing ? '!bg-gray-300 cursor-not-allowed' : ''}`}>
                                      <td
                                        className={`cursor-pointer px-2 group-hover:text-blue-600 py-4 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                        onClick={isProjectRowNotEditing ? null : () => { handleClickProject(project.account_id, project.project_number) }}
                                      >
                                        <div>{project.project_number}</div>
                                      </td>
                                      <td className={`px-2 group-hover:text-blue-600 py-4 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}>
                                        <div>
                                          <button
                                            className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                            onClick={isProjectRowNotEditing ? null : () => { handleClickAccount(project.account_id) }}>
                                            {project.account_name}
                                          </button>
                                        </div>
                                      </td>
                                      <td
                                        className={`px-2 py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                            onClick={isProjectRowNotEditing ? null : () => {
                                              setSearchCategory('site')
                                              setSearchQuery(project.site)
                                            }}
                                          >
                                            {project.site}
                                          </button>
                                        </div>
                                      </td>
                                      <td
                                        className={`px-2 py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                            onClick={isProjectRowNotEditing ? null : () => {
                                              if (builtAddress !== '') {
                                                setSearchQuery(builtAddress)
                                              }
                                            }}
                                          >
                                            {builtAddress === '' ? 'N/A' : builtAddress}
                                          </button>
                                        </div>
                                      </td>
                                      <td
                                        className={`px-2  py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className={`capitalize overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                            onClick={isProjectRowNotEditing ? null : () => {
                                              setSearchCategory('project_type')
                                              setSearchQuery(project.project_type)
                                            }}
                                          >
                                            {project.project_type}
                                          </button>
                                        </div>
                                      </td>
                                      <td
                                        className={`px-2  py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                            onClick={isProjectRowNotEditing ? null : () => {
                                              dispatch(setInstanceProjectStatus({ status: project.status }))
                                            }}
                                          >
                                            {project.status}
                                          </button>
                                        </div>
                                      </td>
                                      <td
                                        className={`px-2  py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                            onClick={isProjectRowNotEditing ? null : (e) => {
                                              // single click
                                              if (e.detail === 1) {
                                                setIsInvoiceAndPaymentsOpen(project.id)
                                                // double click...doesnt work lmao
                                              } else if (e.detail === 2) {
                                                setSearchCategory('fee')
                                                setSearchQuery(project.fee)
                                              }
                                            }}
                                          >
                                            {currencyFormatter().format(project.fee)}
                                          </button>
                                        </div>
                                      </td>
                                      <td
                                        className={`px-2  py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                            onClick={isProjectRowNotEditing ? null : (e) => {
                                              // single click
                                              if (e.detail === 1) {
                                                setIsInvoiceAndPaymentsOpen(project.id)
                                                // double click...doesnt work lmao
                                              } else if (e.detail === 2) {
                                                setSearchCategory('awarded')
                                                setSearchQuery(project.awarded)
                                              }
                                            }}
                                          >
                                            {currencyFormatter().format(project.awarded)}
                                          </button>
                                        </div>
                                      </td>

                                      {
                                        isCommentsOpen === project.id
                                          ? <CommentsModal
                                            comments={project.comments}
                                            closeModal={() => {
                                              setIsCommentsOpen(undefined)
                                            }}
                                          />
                                          : <td
                                            className={`px-2 py-4 group-hover:text-blue-600 text-blue-600 truncate${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}
                                          >
                                            <div className="overflow-hidden w-[140px]">
                                              <button
                                                type="button"
                                                className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                                onClick={isProjectRowNotEditing ? null : () => {
                                                  setIsCommentsOpen(project.id)
                                                }}
                                              >
                                                {/* TODO: replace dangerouslySetInnerHTML with safer inpu */}
                                                {project.comments === '' || !project.comments ? 'N/A' : <div dangerouslySetInnerHTML={{ __html: project.comments }} />}
                                              </button>
                                            </div>
                                          </td>
                                      }

                                      <td className={`px-2 py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}>
                                        <button
                                          onClick={isProjectRowNotEditing ? null : () => {
                                            setProjectToEdit(project)
                                            setEditStatus(project.id)
                                          }}
                                          className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                        >
                                          Edit
                                        </button>
                                      </td>
                                      <td className={`px-2 py-4 group-hover:text-blue-600 text-blue-600${isProjectRowNotEditing ? ' !text-gray-600' : ''}`}>
                                        <button
                                          onClick={isProjectRowNotEditing ? null : () => {
                                            const answer = prompt(`Do you really want to delete the project for ${project.site}? Type y to confirm. (This is temporary, there will be an updated UI modal for this)`)
                                            if (answer === 'y') {
                                              deleteProject(project.project_number)
                                              return
                                            }
                                            return
                                          }}
                                          className={`overflow-scroll h-[40px] text-left ${isProjectRowNotEditing ? 'cursor-not-allowed' : ''}`}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                }
                              </>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          isModalOpen ? <AddProjectModal unique_project_types={unique_project_types} closeModal={async (shouldRefreshData?: boolean) => {
            setIsModalOpen(false)
            if (shouldRefreshData) {
              // await refreshData()
            }
          }} /> : null
        }
      </div>
    </>
  )
}

