import React from 'react';
// import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserType, ALLOWED_ROLES } from '../state/services'

export const ProtectedRoute = ({ children }) => {
  const userRole = useSelector(selectUserType);

  if (!ALLOWED_ROLES.includes(userRole)) {
    // return <Navigate to="/login" replace />;
    return (
      <div>
        N/A.
        Please press back to go to your previous page.
      </div>
    )
  }

  return children;
}